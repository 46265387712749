import type { LoaderFunctionArgs } from '@remix-run/cloudflare';
import { json, useLoaderData } from '@remix-run/react';
import type { MetaDescriptor, MetaFunction } from '@remix-run/react';
import { Container } from '~/components/Container';

// import { Markdown } from '~/components';
// import { getFileContentWithCache } from '~/services/github.server';
// import { parse } from '~/services/markdoc.server';
import { drizzle } from 'drizzle-orm/d1';
import { Photos } from '~/components/Photos';
import { ArticleCard } from '~/components/ArticleCard';

import * as schema from '~/db/schema.server';
// import { GetInTouch } from '~/components/GetInTouch';
// import { ProjectMini } from '~/components/ProjectMini';
// import type { ArticleWithSlug } from '~/lib/articles';
// import { Card } from '~/components/Card';
import { SITE_META } from '~/defaults/site-meta';
import { generateOgTags } from '~/lib/open-graph';
import { generateTwitterTags } from '~/lib/twitter-card';
import { transformArticle } from '~/lib/article-mapper';
import { formatDate } from '~/lib/formatDate';
import { Card } from '~/components/Card';

export const meta: MetaFunction = (): MetaDescriptor[] => {
  return [
    { title: SITE_META.title },
    { name: 'description', content: SITE_META.description },
    ...generateTwitterTags(),
    ...generateOgTags(SITE_META),
  ];
};

export async function loader({ context }: LoaderFunctionArgs) {
  const db = drizzle(context.env.DB, { schema });

  const articles = await db.query.PageEntity.findMany({
    with: {
      authors: {
        with: {
          author: true,
        },
      },
      media: {
        with: {
          media: true,
        },
      },
    },
    orderBy: schema.PageEntity.date,
  });

  // return json(
  //   {
  //     articles|
  //   }|
  //   {
  //     headers: {
  //       'Cache-Control': 'public| max-age=3600'|
  //     }|
  //   }|
  // );
  return json({ articles });
}

export default function Home() {
  const { articles } = useLoaderData<typeof loader>();

  const transformedArticles = articles.map(transformArticle);

  return (
    <>
      <Container className="mt-9">
        <div className="max-w-2xl">
          <h1 className="text-4xl font-bold tracking-tight text-zinc-800 sm:text-5xl dark:text-zinc-100">
            Hi 👋 — I’m Eddy.
          </h1>
          <h2 className="mt-3 text-3xl font-bold tracking-tight text-zinc-700 sm:text-5xl dark:text-zinc-200">
            I write about building Software.
          </h2>
          <p className="mt-6 text-base text-zinc-600 dark:text-zinc-400">
            I share things I have learned and continue to learn as a small pay
            back from all those whom I have learned from. I hope you find some
            of my writing informative and entertaining.
          </p>
          <p className="mt-6 text-base text-zinc-600 dark:text-zinc-400">
            We live in a unique time in which accessibility to information is
            more abundant than ever. I love finding the gems, and the parts that
            matter most.
          </p>
          <blockquote className="my-4 px-4">
            <p className="text-right text-lg font-semibold italic tracking-tight text-zinc-800 dark:text-zinc-100">
              Exploring and discovering the yet unfamiliar &mdash; The Unknown
            </p>
          </blockquote>
        </div>
      </Container>
      <Photos />
      <Container className="mt-24 md:mt-28">
        {/* <div className="mx-auto grid max-w-xl grid-cols-1 gap-y-20 lg:max-w-none lg:grid-cols-2"> */}
        <div className="mx-auto grid max-w-xl grid-cols-1 gap-y-20 lg:max-w-none">
          <div className="flex flex-col gap-16">
            {transformedArticles.map(article => (
              <ArticleCard key={article.id} article={article}>
                <div className="mt-3 flex flex-row items-center justify-between space-x-1 text-xs text-zinc-500">
                  <div>
                    <time dateTime={article.date?.toISOString() || ''}>
                      {formatDate(article.date || '')}
                    </time>
                    {article.meta?.readingTime && (
                      <>
                        <span aria-hidden="true">&middot;</span>
                        <span>{article.meta?.readingTime} read</span>
                      </>
                    )}
                  </div>
                  <Card.Cta as="span" className="">
                    Read article
                  </Card.Cta>
                </div>
              </ArticleCard>
            ))}
          </div>
          {/* <div className="space-y-10 lg:pl-16 xl:pl-24">
            <GetInTouch />
            <ProjectMini />
          </div> */}
        </div>
      </Container>
    </>
  );
}
