import clsx from 'clsx';
import { getURL } from '~/lib/url';

// Travel Images
const eiffel_tower = getURL('eiffel_tower.jpg');
const little_mermaid = getURL('little_mermaid.jpg');
const london_bridge = getURL('london_tower_bridge.jpg');
const golden_gate = getURL('golden_gate_bridge.jpg');
const world_trade_center = getURL('world_trade_center.jpg');

// LA Images
const LA_overlooking = getURL('LA_overlooking.jpg');
const santa_monica_pier1 = getURL('santa_monica_pier_1.jpg');
const santa_monica_pier2 = getURL('santa_monica_pier_2.jpg');
const santa_monica_coast2 = getURL('santa_monica_coast_2.jpg');
const pacific_sunset_2 = getURL('pacific_sunset_2.jpg');

const images_travel = [
  eiffel_tower,
  golden_gate,
  world_trade_center,
  london_bridge,
  little_mermaid,
];

const images_la = [
  LA_overlooking,
  santa_monica_coast2,
  santa_monica_pier1,
  pacific_sunset_2,
  santa_monica_pier2,
];

const imageSet = [images_travel, images_la];

function randomZeroOrOne() {
  return Math.round(Math.random());
}

export function Photos() {
  let rotations = [
    'rotate-2',
    '-rotate-2',
    'rotate-2',
    'rotate-2',
    '-rotate-2',
  ];

  const images = imageSet[randomZeroOrOne()];

  return (
    <div className="mt-16 sm:mt-20">
      <div className="-my-4 flex justify-center gap-5 overflow-hidden py-4 sm:gap-8">
        {images.map((image, imageIndex) => (
          <div
            key={imageIndex}
            className={clsx(
              'relative aspect-[9/10] w-44 flex-none overflow-hidden rounded-xl bg-zinc-100 sm:w-72 sm:rounded-2xl dark:bg-zinc-800',
              rotations[imageIndex % rotations.length],
            )}
          >
            <img
              src={image}
              alt=""
              sizes="(min-width: 640px) 18rem, 11rem"
              className="absolute inset-0 h-full w-full object-cover"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
